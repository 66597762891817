import React from "react";

//components
import RecentPost from "./RecentPost";
// import ContactForm from "../forms/Contact";
import HubSpotForm from "../../components/non-panels/forms/HubSpotForm";
import {
  BlogSubscribeFormReplacementMessage,
  WhitepaperLeadMagnetFormReplacementMessage
} from "../../components/non-panels/forms/replacementElements";
import { addTrailingSlash } from '../../utils/utilities';
// import ShortForm from "../forms/SmallSubscription";

//styles
import s from "./LinkBar.module.scss";
// import "./LinkBar.styles.css";
// import layoutStyle from "../layouts/layout.module.scss";
// import contactStyle from "../forms/Contact.module.scss";

function makeHashLink(slug, uri) {
  return addTrailingSlash(`${uri}/${slug}`);
}

export default function LinkBar({ recentPosts, URIMap, downloadableFile }) {
  // Add hash link to each post.
  recentPosts.forEach(e => {
    const slug = e.slug.endsWith("/") ? e.slug : `${e.slug}/`;
    e["link"] = makeHashLink(e.slug, URIMap[slug]);
  });

  return (
    <div>
      <div className={`card`}>
        <div className={`card-header bg-primary`}>
          <h3 className={`mb-0 mt-2 text-light`}>Subscribe</h3>
        </div>
        <div className={`card-body`}>
          <aside
            style={{ display: "flex", justifyContent: "center" }}
            className={s.linkBar}
          >
            {downloadableFile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%"
                }}
              >
                <HubSpotForm
                  formId="431f4e43-590b-4aa7-aeb4-bccb4a47adf0"
                  replacementElement={
                    <WhitepaperLeadMagnetFormReplacementMessage
                      downloadLink={downloadableFile}
                    />
                  }
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%"
                }}
              >
                <HubSpotForm
                  formId="d0f017e3-30cf-4cf7-98ea-b2b1fce2eec8"
                  replacementElement={<BlogSubscribeFormReplacementMessage />}
                />
              </div>
            )}
          </aside>
        </div>
      </div>
      {/* <h3 className={`${s.sectionHeader}`}>Subscribe</h3> */}
      {/* <aside
        style={{ display: "flex", justifyContent: "center" }}
        className={s.linkBar}
      >
        {downloadableFile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "2rem"
            }}
          >
            <HubSpotForm
              formId="431f4e43-590b-4aa7-aeb4-bccb4a47adf0"
              replacementElement={
                <WhitepaperLeadMagnetFormReplacementMessage
                  downloadLink={downloadableFile}
                />
              }
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "2rem"
            }}
          >
            <HubSpotForm
              formId="d0f017e3-30cf-4cf7-98ea-b2b1fce2eec8"
              replacementElement={<BlogSubscribeFormReplacementMessage />}
            />
          </div>
        )}
      </aside> */}
      <div className={`card mt-5`}>
        <div className={`card-header bg-primary`}>
          <h3 className={`mb-0 mt-2 text-light`}>Recent Posts</h3>
        </div>
        <div className={`card-body p-0`}>
          {recentPosts.map(e => (
            <RecentPost post={e} />
          ))}
        </div>
      </div>
      {/* <aside className={s.linkBar}>
        <div className={s.recentPosts}>
          <h3 className={s.sectionHeader}>Recent Posts</h3>
          <div className={s.links}>
            {recentPosts.map(e => (
              <RecentPost post={e} />
            ))}
          </div>
        </div>
      </aside> */}
    </div>
  );
}
