import React from "react";
import { Link } from "gatsby";

const LinkButton = ({
  to,
  backgroundColorCondition,
  buttonStyles,
  innerText,
  textColor = "white",
  backgroundColor = "#2a2070"
}) => {
  return (
    <Link to={to}>
      <button
        style={
          backgroundColorCondition
            ? { backgroundColor: backgroundColor, color: textColor }
            : null
        }
        className={buttonStyles}
      >
        {innerText}
      </button>
    </Link>
  );
};

export default LinkButton;
