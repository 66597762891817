import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import useSetPageData from "../../utils/useSetPageData";
import SEO from "../../components/seo";

// import ListView from "../ListView";
import Hero from "../../components/panels/Hero";
import LinkBar from "../posts/LinkBar";
import PostItem from "../posts/PostItem";

//styles
import s from "./WebinarList.module.scss";

//utils
import {
  formatUTCStringToLocalDateForPost,
  isDateInFutureOrToday
} from "../../utils/utilities";
import LinkButton from "../../utils/controls/LinkButton";

function extractPostData({ edges }) {
  edges = edges.sort((a, b) => {
    let date1 = a.node["publish_date"] || a.node["created_at"];
    let date2 = b.node["publish_date"] || b.node["created_at"];
    return new Date(date2) - new Date(date1);
  });
  return edges.map(({ node }) => ({ ...node, slug: node.slug + "/" }));
}

export default function MediaList({
  location,
  data: { pageWebinarPosts, recentWebinarPosts, strapiPnWebinars },
  pageContext: { slugToURIMap, baseURI }
}) {
  const { SEO: seo, hero } = strapiPnWebinars;

  useSetPageData(location);

  const [postList, recentPostList] = [pageWebinarPosts, recentWebinarPosts].map(
    extractPostData
  );

  // console.log("postList", postList);

  return (
    <>
      <SEO
        {...{
          title: `${hero[0].main_heading} | Insights`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].about_hero_image.childImageSharp.fixed}
      />
      <div className={`container-fluid mt-4`}>
        <div className={`container`}>
          <div style={{ display: "flex", marginBottom: "1.5rem" }}>
            <LinkButton
              to={`/explainer-videos`}
              backgroundColorCondition={
                hero[0].main_heading === "Explainer Videos"
              }
              buttonStyles={s.sectionButton}
              innerText="Explainer Videos"
            />
            <LinkButton
              to={`/webinar`}
              backgroundColorCondition={hero[0].main_heading === "Webinars"}
              buttonStyles={s.sectionButton}
              innerText="Webinars"
            />
            {/* <LinkButton 
                to={`/media`}
                backgroundColorCondition={hero[0].main_heading === "Media"}
                buttonStyles={s.sectionButton}
                innerText="Media"
              /> */}
          </div>
          <div className={`row`}>
            {/* <div className={`col-md-12 mb-4`}> */}
            {postList.map(post => {
              let date = post.created_at;
              date = formatUTCStringToLocalDateForPost(date);
              const isDateTodayOrFuture = isDateInFutureOrToday(
                post.created_at
              );
              // console.log("post", post);
              return (
                <div className={`col-lg-4 col-md-6 col-sm-12 p-3 mb-2`}>
                  <Link
                    to={`/webinar/${post.slug}`}
                    className={`card  mb-4 ${s.cardLink}`}
                  >
                    <div className={`card-img-top ${s.cardImgTop}`}>
                      <Img
                        className={`${s.cardImg}`}
                        fluid={post.webinar_image.childImageSharp.fluid}
                        style={{
                          borderRadius: "10px 10px 0 0"
                        }}
                      />
                      <div
                        className={` p-4 pt-5 ${s.cardHeadingOverlay}`}
                        style={{
                          background:
                            "linear-gradient(23deg, rgba(34,2,34,.9) 0%, rgba(42,32,112,.9) 100%)"
                        }}
                      >
                        <div
                          className={`${s.cardNotify}`}
                          style={{
                            background: isDateTodayOrFuture ? "#00FF94" : null
                          }}
                          // }}
                        >
                          <span
                            className={s.cardHeadingOverlayText}
                            style={{
                              color: isDateTodayOrFuture ? "black" : null
                            }}
                          >
                            {isDateTodayOrFuture ? "Upcoming" : "On Demand"}
                          </span>
                        </div>
                        {/* <span
                          className={`${
                            isDateTodayOrFuture
                              ? "text-white"
                              : "text-secondary"
                          } ${s.cardDate} p-0 pb-1`}
                          style={{ fontWeight: 900 }}
                        >
                          {date}
                        </span> */}
                        {/* style={{WebkitTextStroke: ".3px white", fontWeight: 600}} */}
                      </div>
                    </div>
                    <div className="card-body p-4">
                      <h4 className={s.cardTitle} style={{ color: "#2a2070" }}>
                        {post.heading}
                      </h4>
                      <hr className={s.divider}></hr>
                      <p className="card-text text-muted">
                        {post.content
                          .replace(/#/g, "")
                          .replace(/_/g, "")
                          // .replaceAll("#", "")
                          // .replaceAll("_", "")
                          .substring(0, 145)}
                        ...
                      </p>
                      {/* <div
                        // href={`/webinar/${post.slug}`}
                        className={`btn btn-link float-right`}
                        style={{
                          borderBottom: "3px solid var(--primary)",
                          borderRadius: 0,
                          padding: "0px 0",
                          fontSize: "unset",
                          fontWeight: 700
                        }}
                        // ${
                        //   isDateTodayOrFuture ? "btn-secondary" : "btn-primary"
                        // }
                      >
                        More
                        <i class="fas fa-chevron-right ml-3"></i>
                      </div> */}
                    </div>
                  </Link>
                </div>
              );
            })}
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* <ListView
        title="Media"
        posts={pageWebinarPosts}
        recentPosts={recentWebinarPosts}
        slugToURIMap={slugToURIMap}
        baseURI={baseURI}
        hero={hero[0]}
        icon={hero[0].about_hero_image.childImageSharp.fixed}
      /> */}
    </>
  );
}

// filter: { published: { eq: true } }
// filter: { published: { eq: true } }
export const webinarListQuery = graphql`
  query webinarListQueryV2($skip: Int!, $limit: Int!, $recentLimit: Int!) {
    pageWebinarPosts: allStrapiPostWebinar(
      sort: { fields: [created_at], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          heading
          publish_date
          created_at: webinar_time
          content: description
          link
          webinar_image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    recentWebinarPosts: allStrapiPostWebinar(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at
          description
          webinar_time
          publish_date
        }
      }
    }
    strapiPnWebinars {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        about_hero_image: insight_image {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
// alter me!
// export const mediaListQuery = graphql`
//   query mediaListQuery($skip: Int!, $limit: Int!, $recentLimit: Int!) {
//     pageMediaPosts: allStrapiPostMedia(
//       sort: { fields: [updated_at], order: DESC }
//       limit: $limit
//       skip: $skip
//     ) {
//       edges {
//         node {
//           id
//           slug
//           heading
//           content
//           event_start_date
//           event_end_date
//           location
//           media_image {
//             childImageSharp {
//               fluid(maxWidth: 1000) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//           created_at
//         }
//       }
//     }
//     recentMediaPosts: allStrapiPostMedia(
//       sort: { fields: [updated_at], order: ASC }
//       limit: $recentLimit
//     ) {
//       edges {
//         node {
//           heading
//           id
//           slug
//           created_at
//           location
//           event_start_date
//           event_end_date
//         }
//       }
//     }
//     strapiPaMedia {
//       SEO {
//         description
//         keywords
//       }
//       hero {
//         main_heading
//         main_subheading
//         about_hero_image {
//           childImageSharp {
//             fixed(width: 250) {
//               ...GatsbyImageSharpFixed
//             }
//           }
//         }
//         about_hero_cover_image {
//           childImageSharp {
//             fluid {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `;
