import React from "react";
import { Link } from "gatsby";

import { formatUTCStringToLocalDateForPost } from "../../utils/utilities";

import s from "./RecentPost.module.scss";

export default function RecentPost({ post }) {
  const { heading, created_at, link, publish_date } = post;
  let date = publish_date || created_at;
  date = formatUTCStringToLocalDateForPost(date);

  return (
    <div className={`${s.recentPost}`}>
      <Link className={s.link} to={link}>
        {heading}
      </Link>
      <span className={s.date}>{date}</span>
    </div>
  );
}
